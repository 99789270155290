<template>
  <div id="page-user-list">
    <vs-popup title="Transfert d'article" :active.sync="popupTransferer">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vs-select disabled label="Officine d'origine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="Officine_origine">
            <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/2">
          <vs-select label="Officine destination" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="Officine_destination">
            <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
          </vs-select>
        </div>
        <div class="vx-col w-full mt-5">
          <vs-textarea label="Observation" v-model="observation" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-2/3 w-full ml-auto">
          <vs-button class="mr-3 mb-2" @click="TransfererArticle">Transferer</vs-button>
          <vs-button color="warning" type="border" class="mb-2" @click="Officine_destination = observation = null; popupTransferer = false;">Annuler</vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-alert color="danger" title="Error transfert" :active="alert">
      Impossible de lancer un transfert aucun article selectionné!
    </vs-alert>
    <div class="vx-card p-6 mt-5" id="user-list-body">
      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ stocks.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : stocks.length }} of {{ stocks.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(35)">
                <span>35</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(40)">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(45)">
                <span>45</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            class="btn-primary ml-4 mb-2 sm:mb-0"
            color="primary"
            @click="TransfererArticleForme">
            Transferer article (s)
          </vs-button>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="CurrentOfficineSelectStock()">
            <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
          </vs-select>
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Recherche..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Facture</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Exporter</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="stocks"
        :rowSelection="rowSelection"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        @selection-changed="onSelectionChanged"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

// Store Module

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererMarque from './cell-renderer/CellRenderMarque.vue'
import CellRendererCategorie from './cell-renderer/CellRendererCategorie.vue'
import CellRendererStyle from './cell-renderer/CelleRendererStyle.vue'
import CellRendererType from './cell-renderer/CellRendererType.vue'
import CellRendererTaille from './cell-renderer/CellRendererTaille.vue'


export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererMarque,
    CellRendererCategorie,
    CellRendererStyle,
    CellRendererType,
    CellRendererTaille
  },
  data () {
    return {
      rowSelection: null,
      searchQuery: '',
      idOfficine: null,
      activeUserInfos: null,
      articles_a_transferer: [],
      popupTransferer: false,
      Officine_origine: null,
      Officine_destination: null,
      observation: null,
      alert: false,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Etiquette',
          field: 'Etiquette',
          filter: true,
          width: 250,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'Marque',
          field: 'IdMarque',
          filter: true,
          width: 250,
          cellRendererFramework: 'CellRendererMarque'
        },
        {
          headerName: 'Modèle',
          field: 'Modele',
          filter: true,
          width: 200
        },
        {
          headerName: 'Catégorie',
          field: 'IdCateg',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererCategorie'
        },
        {
          headerName: 'Qte',
          field: 'stock',
          filter: true,
          width: 125,
          cellClass: 'text-center',
          // eslint-disable-next-line no-confusing-arrow
          cellStyle: params => params.value > params.data.stockMinimun ? {backgroundColor: 'green', color: '#FFFFFF'} : {backgroundColor: 'red', color: '#FFFFFF'}
        },

        {
          headerName: 'Min',
          field: 'stockMinimun',
          filter: true,
          width: 125,
          cellClass: 'text-center',
          // eslint-disable-next-line no-confusing-arrow
          cellStyle: params =>  params.value >= 1 ? {backgroundColor: 'orange', color: '#FFFFFF'} : {backgroundColor: 'red', color: '#FFFFFF'}
        },
        {
          headerName: 'PR',
          field: 'PrixAchatBrut',
          filter: true,
          width: 150
        },
        {
          headerName: 'PV',
          field: 'PrixVenteTTC',
          filter: true,
          width: 150
        },
        {
          headerName: 'Prix planché',
          field: 'PrixPlanche',
          filter: true,
          width: 125,
          cellClass: 'text-center'
        },
        {
          headerName: 'Couleur',
          field: 'Couleur',
          filter: true,
          width: 250
        },
        {
          headerName: 'Style',
          field: 'IdStyle',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererStyle'
        },
        {
          headerName: 'Type',
          field: 'IdTypeArt',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererType'
        },
        {
          headerName: 'Taille',
          field: 'Taille',
          filter: true,
          width: 200,
          cellRendererFramework: 'CellRendererTaille'
        },
        {
          headerName: 'Cote B',
          field: 'coteB',
          filter: true,
          width: 200
        },

        {
          headerName: 'Unité',
          field: 'UniteMesure',
          filter: true,
          width: 150
        },
        {
          headerName: 'Code barre',
          field: 'CodeBarre',
          filter: true,
          width: 125,
          cellClass: 'text-center'
        },
        {
          headerName: 'Rémise',
          field: 'Remise',
          filter: true,
          width: 125,
          cellClass: 'text-center'
        }
        //{
        //  headerName: 'Actions',
        //  field: '_id',
        //  width: 150,
        //  cellRendererFramework: 'CellRendererActions'
        //}
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions
      }
    }
  },


  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    stocks () {
      return this.$store.state.stock.articles
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 50
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getStocks () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('stock/getStocks')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getStockByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('stock/getStockByOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine
      this.Officine_origine = this.activeUserInfos.IdOfficine

      this.getStockByOfficine(this.idOfficine)
    },
    TransfererArticleForme () {

      if (this.articles_a_transferer.length === 0) {
        this.alert = true
      } else {
        this.alert = false
        this.popupTransferer = true
      }
    },
    CurrentOfficineSelectStock () {
      this.Officine_origine = this.idOfficine

      this.getStockByOfficine(this.idOfficine)
    },
    onSelectionChanged () {
      this.articles_a_transferer = this.gridApi.getSelectedRows()
    },
    annulerTransferer () {
      this.popupTransferer = false
    },
    TransfererArticle () {
      const payload = {
        Officine_origine: this.Officine_origine,
        Officine_destination: this.Officine_destination,
        observation: this.observation,
        details: this.articles_a_transferer
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('stock/transfererArticle', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })

    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })

    }

  },
  mounted () {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.activeUserInfo()
    /*  this.getStocks() */
    this.getOfficines()
    this.getMarques()
    this.getCategorieArticles()
    this.rowSelection = 'multiple'
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
