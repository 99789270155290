<template>
  <div class="flex items-center">
    {{getCategorieArticleById()}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererMarque',
  props: ['categorieId'],
  computed: {

  },
  methods: {
    getCategorieArticleById () {
      const categorie = this.$store.getters['categorie_article/getCategorieArticleById'](this.params.value)
      return categorie ? categorie.Abreg_Categ : ''

    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })

    }
  },
  created () {
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticles()
  }
}
</script>
