<template>
  <div class="flex items-center">
    {{getMarqueById()}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererMarque',
  props: ['marqueId'],
  computed: {

  },
  methods: {
    getMarqueById () {
      console.log('marqueId:', this.params.value)
      const marque = this.$store.getters['marque/getMarqueById'](this.params.value)
      console.log('marque:', marque)
      return marque ? marque.Libelle_marq : ''

    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
  }
}
</script>
