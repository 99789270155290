<template>
  <div class="flex items-center">
    {{getTypeById()}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererMarque',
  props: ['typeId'],
  computed: {

  },
  methods: {
    getTypeById () {
      const type = this.$store.getters['type/getTypeById'](this.params.value)
      return type ? type.Type_Abreg : ''

    },
    getTypes () {
      this.$store.dispatch('type/getTypes')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    if (!(this.$store.state.type.types.length > 0)) this.getTypes()
  }
}
</script>
