<template>
  <div class="flex items-center">
    {{getStyleById()}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererMarque',
  computed: {

  },
  methods: {
    getStyleById () {
      const style = this.$store.getters['style/getStyleById'](this.params.value)
      return style ? style.StyleAbreg : ''

    },
    getStyles () {
      this.$store.dispatch('style/getStyles')
        .then(() => { })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getStyles()
  }
}
</script>
