<template>
  <div class="flex items-center">
    {{params.value[0].Longeur}}/{{params.value[0].Nez}}/{{ params.value[0].Hauteur}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererTaille',
  data: () => {
    return {
      taille: null
    }
  },
  computed: {
    url () {
      return '/apps/user/user-view/268'

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    }
  },
  created () {
    this.taille = this.params.value
  }
}
</script>
